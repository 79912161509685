
import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { IonApp } from "@ionic/vue";

const PublicInfoModule = namespace("public-info");
const SubmitRegistrationModule = namespace("submit-registration");

@Options({
  components: {
    IonApp,
  },
})
export default class Onboarding extends Vue {
  @PublicInfoModule.Action("getDrivingSchool")
  public getDrivingSchoolAction: any;

  @PublicInfoModule.Getter("getIsLoading")
  public loading: any;

  @SubmitRegistrationModule.Action("resetSave")
  public resetRegistrationAction: any;

  public created(): void {
    this.resetRegistrationAction();
  }

  public mounted(): void {
    this.getDrivingSchoolAction(this.$route.params.drivingSchool);
  }
}
